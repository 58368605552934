<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Job Sheet
      </h3>
      <hr class="flex-1 border-primary mx-4"/>
    </div>

    <div class="flex flex-wrap w-full">
      <template>
        <DataTable
            class="w-full"
            stripedRows
            :sortable="true"
            :loading="isLoading"
            :value="tableData"
            :paginator="true"
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            sortField="created_at"
            sortOrder="-1"
            selectionMode="single"
            @row-select="onRowSelect"
            @row-click="onRowClick"
            @page="onPageChange"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[1, 2, 5, 10, 20]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :lazy="false"
        >
          <!-- Standard columns -->
          <Column
              v-for="col in columns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
              :sortable="col.sortable"
              :headerStyle="{ width: col.width }"
          >
            <template v-if="col.custom" #body="slotProps">
              <!-- Engineer Name -->
              <template v-if="col.slotName=='engineer-name-slot'">
                <div
                    v-if="slotProps.data.engineer && slotProps.data.engineer.first_name && slotProps.data.engineer.last_name">
                  {{ slotProps.data.engineer.first_name }} {{ slotProps.data.engineer.last_name }}
                </div>
                <div v-else>
                  Not Available
                </div>
              </template>

              <!-- Job Sheet Date -->
              <template v-if="col.slotName=='job-sheet-date-slot'">
                <div v-if="slotProps.data.created_at">
                  {{ formatTimestamp(slotProps.data.created_at) }}
                </div>
                <div v-else>
                  Date Unknown
                </div>
              </template>
            </template>
          </Column>
          <template #empty>
            No records found.
          </template>
        </DataTable>
      </template>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    contactId: String,
  },
  data() {
    return {
      isLoading: false,
      tableData: [],
      rowsPerPage: 10,
      totalRecords: 0,
      sortField: 'job_sheet_no',
      sortOrder: 1,
      columns: [
        {field: 'equipment.product_code', header: 'Code', sortable: true, width: '10%', custom: false,},
        {field: 'equipment.name', header: 'Description', sortable: true, width: '25%', custom: false},
        {field: 'equipment.serial_number', header: 'Serial Number', sortable: true, width: '20%', custom: false},
        {
          field: 'engineer.first_name',
          header: 'Engineer Name',
          sortable: true,
          width: '15%',
          custom: true,
          slotName: "engineer-name-slot",
        },
        {field: 'job_sheet_no', header: 'Job Sheet No.', sortable: true, width: '15%', custom: false},
        {
          field: 'created_at',
          header: 'Created Date',
          sortable: true,
          width: '15%',
          custom: true,
          slotName: "job-sheet-date-slot",
        },
      ],
    };
  },
  methods: {
    onRowSelect(event) {
      console.log('Row selected:', event.data);
    },
    async onRowClick(event) {

      if (event.data && event.data.job_sheet_id) {
        await this.$router.push({
          name: "engineer-job-sheet-edit",
          params: {
            job_sheet_id: event.data.job_sheet_id
          },
        });
      }
    },
    onPageChange(event) {
      console.log('Page changed:', event.page);
    },
    async fetchInitialData() {
      this.tableData = await this.JobSheetService.getJobSheetsByCustomerId(this.contactId)
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
  },
  async mounted() {
    await this.fetchInitialData();
  },
};
</script>
